/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-12",
    versionChannel: "nightly",
    buildDate: "2024-09-12T00:06:03.350Z",
    commitHash: "3b6d5088d1b49e38698f3750212a5dea0783b0d7",
};
